import React from "react";

function ContactForm() {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Custom Solutions for Your Industry</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Whether you're in healthcare, military, workplace or sports,
                WellSole offers custom solutions to meet the unique needs of
                your industry. Reach out to us to discuss how WellSole can help
                you achieve your goals.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6  mt-4 pt-2">
            <div className="card shadow rounded border-0">
              <div className="card-body py-5">
                <h4 className="card-title">Get In Touch !</h4>
                <div className="custom-form mt-3">
                  <form>
                    <p id="error-msg" className="mb-0"></p>
                    <div id="simple-msg"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Your Name <span className="text-danger">*</span>
                          </label>
                          <div className="form-icon position-relative">
                            <i className="uil uil-user icon-sm icons"></i>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Name :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </label>
                          <div className="form-icon position-relative">
                            <i className="uil uil-envelope icon-sm icons"></i>
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control ps-5"
                              placeholder="Email :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Subject</label>
                          <div className="form-icon position-relative">
                            <i className="uil uil-book icon-sm icons"></i>
                            <input
                              name="subject"
                              id="subject"
                              className="form-control ps-5"
                              placeholder="subject :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Comments <span className="text-danger">*</span>
                          </label>
                          <div className="form-icon position-relative">
                            <i className="uil uil-message icon-sm icons clearfix"></i>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control ps-5"
                              placeholder="Message :"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-grid">
                          <button
                            type="submit"
                            id="submit"
                            name="send"
                            className="btn btn-primary"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 ps-md-3 pe-md-3 mt-4 pt-2">
            <div className="card map map-height-two rounded map-gray border-0">
              <div className="card-body p-0">
                <iframe
                  title="MAP"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.5988129296816!2d-71.30563252331142!3d42.26573927120189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3873bd9f10659%3A0x99a5c1821761a8a2!2s10%20Beldon%20Rd%2C%20Dover%2C%20MA%2002030%2C%20USA!5e0!3m2!1sen!2s!4v1726560533678!5m2!1sen!2s"
                  style={{ border: 0 }}
                  className="rounded"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-100 mt-60">
        <div className="row">
          <div className="col-md-4">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-phone rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Phone</h5>
                <p className="text-muted">Contact Wellsole Via Phone</p>
                <a href="tel:+152534-468-854" className="read-more">
                  +152 534-468-854
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-envelope rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Email</h5>
                <p className="text-muted">Contact Wellsole Via Email</p>
                <a href="mailto: info@wellsole.com" className="read-more">
                  info@wellsole.com
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-map-marker rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Location</h5>
                <p className="text-muted">
                  10 Beldon Rd, Dover, <br /> MA 02030, USA
                </p>
                <a
                  href="https://www.google.com/maps/place/10+Beldon+Rd,+Dover,+MA+02030,+USA/@42.2659578,-71.3008268,15.19z/data=!4m6!3m5!1s0x89e3873bd9f10659:0x99a5c1821761a8a2!8m2!3d42.2657393!4d-71.3030576!16s%2Fg%2F11c16mdnsq!5m1!1e4?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D"
                  data-type="iframe"
                  className="video-play-icon read-more lightbox"
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Google map
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
